export const logos = [
  {
    name: "Github",
    svg: (
      <svg
        width="27"
        height="27"
        viewBox="0 0 16 16"
        fill="#d0cdd7"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Github</title>
        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
      </svg>
    ),
    color: "#d0cdd7",
  },
  {
    name: "LinkedIn",
    svg: (
      <svg
        width="27"
        height="27"
        viewBox="0 0 16 16"
        fill="#d0cdd7"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>LinkedIn</title>
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
      </svg>
    ),
    color: "#d0cdd7",
  },
  {
    name: "Hamburger Menu",
    svg: (
      <svg
        width="36"
        height="36"
        viewBox="0 0 16 16"
        fill="#061f27"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Hamburger Menu</title>
        <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
      </svg>
    ),
    color: "#061f27",
  },
  {
    name: "Scroll-To-Top Arrow",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#d0cdd7"
        stroke="currentColor"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Scroll-To-Top Arrow</title>
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 11l5-5m0 0l5 5m-5-5v12"
        />
      </svg>
    ),
    color: "#d0cdd7",
  },
  {
    name: "PH Logo",
    svg: (
      <svg
        width="69"
        height="66"
        viewBox="0 0 69 66"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.628 42.36C22.996 42.36 21.532 41.916 20.236 41.028C18.964 40.14 17.932 39.072 17.14 37.824V49.668H15.916V23.28H17.068V27.168C17.86 25.944 18.904 24.948 20.2 24.18C21.52 23.388 22.888 22.992 24.304 22.992C25.6 22.992 26.788 23.268 27.868 23.82C28.972 24.372 29.92 25.116 30.712 26.052C31.528 26.964 32.152 27.996 32.584 29.148C33.04 30.3 33.268 31.476 33.268 32.676C33.268 34.404 32.908 36 32.188 37.464C31.468 38.928 30.46 40.116 29.164 41.028C27.892 41.916 26.38 42.36 24.628 42.36ZM24.448 41.208C25.6 41.208 26.644 40.968 27.58 40.488C28.516 40.008 29.308 39.36 29.956 38.544C30.628 37.728 31.144 36.816 31.504 35.808C31.864 34.8 32.044 33.756 32.044 32.676C32.044 31.548 31.84 30.48 31.432 29.472C31.024 28.44 30.448 27.528 29.704 26.736C28.984 25.944 28.144 25.32 27.184 24.864C26.248 24.384 25.228 24.144 24.124 24.144C23.428 24.144 22.684 24.288 21.892 24.576C21.124 24.864 20.38 25.272 19.66 25.8C18.964 26.304 18.388 26.88 17.932 27.528C17.476 28.176 17.212 28.848 17.14 29.544V35.988C17.596 36.972 18.184 37.86 18.904 38.652C19.648 39.42 20.488 40.044 21.424 40.524C22.384 40.98 23.392 41.208 24.448 41.208ZM52.6872 42H51.4632V31.56C51.4632 29.112 51.0432 27.264 50.2032 26.016C49.3632 24.768 48.1392 24.144 46.5312 24.144C45.4512 24.144 44.3832 24.42 43.3272 24.972C42.2952 25.5 41.3952 26.232 40.6272 27.168C39.8832 28.08 39.3432 29.124 39.0072 30.3V42H37.7832V15.72H39.0072V27.96C39.7752 26.448 40.8552 25.248 42.2472 24.36C43.6632 23.448 45.1632 22.992 46.7472 22.992C47.7552 22.992 48.6192 23.184 49.3392 23.568C50.0832 23.928 50.6952 24.48 51.1752 25.224C51.6792 25.944 52.0512 26.82 52.2912 27.852C52.5552 28.884 52.6872 30.072 52.6872 31.416V42Z"
          fill="currentColor"
        />
        <line x1="0.5" x2="0.5" y2="20" stroke="currentColor" />
        <line y1="0.5" x2="20" y2="0.5" stroke="currentColor" />
        <line x1="69" y1="0.5" x2="49" y2="0.5" stroke="currentColor" />
        <line x1="68.5" x2="68.5" y2="20" stroke="currentColor" />
        <line x1="68.5" y1="66" x2="68.5" y2="46" stroke="currentColor" />
        <line x1="69" y1="65.5" x2="49" y2="65.5" stroke="currentColor" />
        <line y1="65.5" x2="20" y2="65.5" stroke="currentColor" />
        <line x1="0.5" y1="66" x2="0.5" y2="46" stroke="currentColor" />
      </svg>
    ),
    color: "#d0cdd7",
  },
];
